<template>
    <div id="agbWraper">
        <div class="container">
            <h1>AGB</h1>
            <p>Präambel</p>
            <p>Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) regeln das Verhältnis zwischen der Digital-Mail-Solution GmbH und dem Internetnutzer, der das Angebot auf pausenhof.de nutzt (Nutzer). Für dieses Verhältnis gelten allein diese AGB sowie das deutsche Recht. AGB des Nutzers gelten nicht. Vertragssprache ist Deutsch.</p>
            <p>&nbsp;</p>
            <p><strong>§ 1    Teilnahmeberechtigung</strong></p>
            <p>Zur Nutzung des Angebots auf pausenhof.de, insbesondere zum Download sind ausschließlich Personen berechtigt, die zum Zeitpunkt ihrer Teilnahme das 18. Lebensjahr vollendet haben und ihren ständigen Wohnsitz in einem Land der Europäischen Union oder der Schweiz haben.</p>
            <p>&nbsp;</p>
            <p><strong>§ 2    Registrierung</strong></p>
            <p>Für die Registrierung sind mindestens die Pflichtfelder in der Registrierungsmaske wahrheitsgemäß und vollständig auszufüllen. Dies sind:</p>
            <p>e-Mail-Adresse</p>
            <p>Änderungen dieser Daten (z. B. bei einem Umzug oder dem Wechsel der E-Mailadresse), hat der Nutzer der Digital-Mail-Solution GmbH unverzüglich mitzuteilen. Nutzer sind verpflichtet, wahrheitsgemäße Angaben zu machen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 3    Teilnahme</strong></p>
            <p>Die Teilnahme / der Download ist nur über das Internet (Online) möglich.</p>
            <p>&nbsp;</p>
            <p><strong>§ 4    Werbeeinverständnis</strong></p>
            <p>Mit der Angabe seiner persönlichen Daten erklärt der Nutzer sein Einverständnis, dass er von Digital-Mail-Solution GmbH und den <u><a class="eModal-1" href="#">hier genannten Sponsoren</a></u> Werbung per E-Mail an die vom Nutzer angegebene E-Mail-Adresse erhält.  Der Nutzer kann der werblichen Nutzung seiner Daten durch Digital-Mail-Solution GmbH jederzeit durch eine E-Mail an info @ digital-mail-solution.com widersprechen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 5    Disqualifikation</strong></p>
            <p>Digital-Mail-Solution GmbH kann Nutzer von der Teilnahme am Service von pausenhof.de ausschließen, sofern hierdurch kein Verstoß gegen das Allgemeine Gleichbehandlungsgesetz entsteht.</p>
            <p>&nbsp;</p>
            <p><strong>§ 6    Beendigung</strong></p>
            <p>Digital-Mail-Solution GmbH ist berechtigt, das Angebot unter pausenhof.de jederzeit und ohne Vorankündigung zu beenden oder nach ihrem Ermessen zu ändern.</p>
            <p>Dies gilt insbesondere, wenn technische, rechtliche oder sicherheitsrelevante (Computerviren, Hackerangriff) Probleme auftreten.</p>
            <p>&nbsp;</p>
            <p><strong>§ 7    Datenschutz</strong></p>
            <p>Hinsichtlich des Datenschutzes gilt unsere separate Datenschutzerklärung. Diese finden Sie unter <a href="https://www.digital-mail-solution.com/datenschutz/" target="_blank" rel="nofollow noopener">https://www.digital-mail-solution.com/datenschutz/</a></p>
            <p>&nbsp;</p>
            <p><strong>§ 8    Rechtsweg</strong></p>
            <p>Es besteht kein einklagbarer Anspruch des Nutzers gegen Digital-Mail-Solution GmbH auf Teilnahme am Angebot unter pausenhof.de, auf Auszahlung eines Gewinns oder Durchführung eines Downloads.</p>
            <p>&nbsp;</p>
            <p><strong>§ 9    Haftung</strong></p>
            <p>Digital-Mail-Solution GmbH haftet nicht für Verlust, Verzögerung, (teilweise) Löschung, Veränderung, Beschädigung, Manipulation, Fehlleitung von E-Mails und Daten sowie fehlerhafte Informationen, die ihre Ursachen außerhalb des Bereichs der Digital-Mail-Solution GmbH haben.</p>
            <p>Digital-Mail-Solution GmbH übernimmt für die Vollständigkeit, Richtigkeit, Aktualität und Verfügbarkeit des zugrunde liegenden Datenmaterials keinerlei Haftung.</p>
            <p>Im Übrigen haftet Digital-Mail-Solution GmbH nur für grobe Fahrlässigkeit oder vorsätzliches Handeln seiner gesetzlichen Vertreter, Mitarbeiter und Erfüllungsgehilfen.</p>
            <p>Der vorstehende Haftungsausschluss greift nicht, soweit zwingende gesetzliche Vorschriften entgegenstehen, so z. B. bei der Verletzung von Leben, Körper oder Gesundheit.</p>
            <p>Verletzen Digital-Mail-Solution GmbH oder eine Person, für deren Verhalten Digital-Mail-Solution GmbH haftet, wesentliche Vertragspflichten, ist der Schadensersatzanspruch auf den vertragstypischen, voraussehbaren Schaden begrenzt, soweit gesetzlich zulässig.</p>
            <p>&nbsp;</p>
            <p><strong>§ 10    Verlinkungen</strong></p>
            <p>Durch eine Verlinkung macht Digital-Mail-Solution GmbH sich die Inhalte der verlinkten Seiten ausdrücklich nicht zu eigen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 11    Urheberrecht</strong></p>
            <p>Alle auf pausenhof.de befindlichen Inhalte unterliegen dem Urheberrecht. Die Urheberrechte werden entweder von Digital-Mail-Solution GmbH selbst oder gegebenenfalls von einem anderen Lizenzgeber beansprucht und auch rechtlich strikt durchgesetzt.</p>
            <p>Die in Anspruch genommenen Urheberrechte beziehen sich auch auf das Design/Layout des Angebotes sowie alle Stilkomponenten. Darüber hinaus finden die EG-Richtlinien und Bestimmungen, internationale Übereinkünfte und sonstige anwendbare Copyright-Gesetze Anwendung.</p>
            <p>Die Verwertung und Nutzung der angebotenen Inhalte bedarf der ausdrücklichen und schriftlichen Genehmigung Digital-Mail-Solution GmbHs.</p>
            <p>Digital-Mail-Solution GmbH stellt Privatnutzern Informationen zu Firmen, Institutionen, Produkten und Dienstleistungen sowie Konditionen unentgeltlich zur Verfügung. Mit dem Leistungsabruf erklärt sich der Nutzer damit einverstanden, dass Digital-Mail-Solution GmbH die von ihm eingegebenen Daten zur Errechnung eines detaillierten Datenbankabrufs verwendet. Hierbei ist auch die anonyme Weiterleitung der Daten einbezogen.</p>
            <p>Die vom Betreiber angegebenen Urheberrechtshinweise, Quellenangaben und Markenbezeichnungen dürfen weder verändert noch beseitigt werden.<br />
                Die Weitergabe erstellter Kopien im Sinne vorstehender Ausführungen darf ausschließlich nach schriftlicher Zusage Digital-Mail-Solution GmbHs erfolgen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 12    Kosten</strong></p>
            <p>Die Informationsabfrage des Nutzers ist generell gebührenfrei – kostenpflichtige Angebote sind ausdrücklich als solche gekennzeichnet. Kosten seines Internet-Zugangs, möglicherweise zeit- oder datenvolumenabhängig, hat der Nutzer selbst zu tragen. Es bleibt Digital-Mail-Solution GmbH vorbehalten, durch technische Maßnahmen die Datentransfer-Rate / Download-Geschwindigkeit festzulegen und zu begrenzen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 13 Verträge mit Dritten</strong></p>
            <p>Daten und Informationen zu Produkten, Dienstleistungen und Anbietern kommen ausschließlich von den Anbietern (Dritten). Digital-Mail-Solution GmbH ist nicht verpflichtet, diese Drittangaben zu prüfen. Sofern Digital-Mail-Solution GmbH diese Drittangaben zur Verfügung stellt, erfolgt dies ohne Gewähr für deren Inhalt, Vollständigkeit, Aktualität und Richtigkeit.<br />
                Sofern Digital-Mail-Solution GmbH dem Nutzer einen Zugang zu Datenbanken oder Diensten Dritter vermittelt, haftet Digital-Mail-Solution GmbH weder für Bestand noch für die Sicherheit dieser Datenbanken oder Dienste, noch für den Datenschutz, die inhaltliche Richtigkeit, Vollständigkeit oder Aktualität sowie die Freiheit von Rechten Dritter bezüglich der durch den Nutzer erhaltenen Daten, Informationen und Programme.</p>
            <p>Als Vermittlung zum Zugang zur Nutzung gilt auch die automatische und kurzzeitige Vorhaltung fremder Inhalte auf der Website. Die konkreten Angebote der Produkt- oder Dienstleistungsanbieter können von den angegebenen Bedingungen / Konditionen abweichen, welche Digital-Mail-Solution GmbH durch die Produkt- oder Dienstleistungsanbieter zur Verfügung gestellt wurden.</p>
            <p>Ein möglicher Vertrag kommt ausschließlich direkt zwischen dem Nutzer und dem Produkt- oder Dienstleistungsanbieter zustande. Digital-Mail-Solution GmbH handelt lediglich als Bote oder richtet ein System ein, das die Aufgaben eines Boten erfüllt. Digital-Mail-Solution GmbH tritt nicht als Makler, Vermittler oder Vertreter auf, gleich für welche Vertragspartei. Über das Zustandekommen eines Vertrags mit einem Produkt- oder Dienstleistungsanbieter entscheidet allein der entsprechende Produkt- oder Dienstleistungsanbieter, Digital-Mail-Solution GmbH kann hierauf keinen Einfluss nehmen. Es wird dem Nutzer daher empfohlen, sich vor Abschluss eines Vertrags Klarheit über folgende Konditionen des Drittanbieters zu verschaffen: Seine Identität, die wesentlichen Merkmale der Ware / Dienstleistung sowie den Zeitpunkt, zu dem der Vertrag zustande kommt; die Mindestlaufzeit des Vertrages, den Vorbehalt, eine in Qualität und Preis vergleichbare Leistung zu erbringen sowie den Vorbehalt, die versprochene Leistung im Falle der Nichtverfügbarkeit nicht zu erbringen; den Preis der Ware einschließlich aller Steuern und sonstigen Abgaben; gegebenenfalls zusätzlich anfallende Bereitstellungs- oder Stornokosten; Einzelheiten hinsichtlich der Zahlung der Leistung oder Erfüllung; das Bestehen eines Widerrufsrechts und dessen Voraussetzungen; die Gültigkeitsdauer befristeter Angebote insbesondere hinsichtlich des Preises oder weiterer Kosten.</p>
            <p>Digital-Mail-Solution GmbH übernimmt keine Gewähr für Richtigkeit, Vollständigkeit oder Zeitpunkt der Datenübermittlung. Es obliegt alleinig dem Nutzer, sich durch weitere Kommunikationswege ergänzend zu dem von Digital-Mail-Solution GmbH zur Verfügung gestellten Dienst von dem ordnungsgemäßen Zustandekommen der zwischen ihm und dem Produkt- und Dienstleistungsanbieter (Dritten) avisierten oder geschlossenen Vereinbarungen zu überzeugen und sich zu vergewissern.</p>
            <p>&nbsp;</p>
            <p><strong>§ 14    Downloads</strong></p>
            <p>Stellt Digital-Mail-Solution GmbH Software oder Informationen zum Download aus eigener Datenbank oder aus den Datenbanken Dritter zur Verfügung, gilt ergänzend folgendes:</p>
            <p>Rechte an der Software, Shareware und Freeware (nachfolgend Software genannt) liegen ausschließlich bei dem Autor / Urheber derselben. Digital-Mail-Solution GmbH ist weder für den Nutzer noch den Autor / Urheber als Handelsvertreter, Makler, Vermittler oder Berater tätig.</p>
            <p>Digital-Mail-Solution GmbH ist weder befugt noch berechtigt, rechtsverbindliche Erklärungen für den Nutzer oder den Autor / Urheber wechselseitig oder Dritten gegenüber abzugeben. Rechtsbeziehungen entstehen nur zwischen dem Nutzer und dem Autor / Urheber.</p>
            <p>Die Verantwortung und Haftung für die Software liegt ausschließlich beim Autor / Urheber. Digital-Mail-Solution GmbH haftet nicht für die Beschaffenheit, Funktionalität oder Mangelfreiheit der Software und der Download-Daten. Virenfreiheit oder Freiheit von vergleichbaren Fehlern wird für die Software nicht zugesichert, eine Haftung nicht übernommen.</p>
            <p>Digital-Mail-Solution GmbH empfiehlt dem Nutzer unbedingt den Einsatz eines aktuellen Virenschutzprogramms und die Überprüfung aller auf sein System geladener Daten vor der Nutzung.</p>
            <p>Der Nutzer sichert zu, die Software nur nach Maßgabe der Regelungen des Autors / Urhebers einzusetzen.</p>
            <p>Download-Daten Dritter sind mit einem Herkunftsnachweis gekennzeichnet. Es können bezüglich des Downloads dieser Daten weitere Nutzungsregelungen und -hinweise gelten. Der Nutzer versichert sich durch Kontakt zu dem Dritten vor dem Download über die möglichen weiteren Konditionen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 15    Forum</strong></p>
            <p>Wird auf dem Portal ein Forum angeboten, soe steht der Nutzer dafür ein, dass sämtliches von ihm eingestelltes Material frei von Rechten Dritter (insbesondere Persönlichkeits-, Urheber- und Markenrechten) und Rechtsverletzungen ist und stellt Digital-Mail-Solution GmbH von allen Ansprüchen Dritter sowie angemessenen Kosten der Rechtsverteidigung frei, die diese wegen des Materials gegen Digital-Mail-Solution GmbH richten.</p>
            <p>Digital-Mail-Solution GmbH kann nach freiem Ermessen und ohne Vorankündigung jedwedes Material entfernen und vernichten. Der Nutzer ist ferner damit einverstanden, dass die Digital-Mail-Solution GmbH die ihr vorliegenden Daten im Falle eines behaupteten Anspruchs Dritter an den Dritten herausgibt.</p>
            <p>Digital-Mail-Solution GmbH haftet nicht für Forenbeiträge und ist nur auf Aufforderung unter Darlegung und Nachweis eines Rechtsverstoßes verpflichtet, einen Forenbeitrag zu entfernen.</p>
            <p>&nbsp;</p>
            <p><strong>§ 16    Erfüllungsort und Gerichtsstand</strong></p>
            <p>Erfüllungsort für sämtliche vertragliche Leistungen und Gerichtsstand ist Großbritannien, sofern der Nutzer Kauffrau / Kaufmann, juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist (§§ 729 und 738 der Zivilprozessordnung).</p>
            <p>&nbsp;</p>
            <p><strong>§ 17 Salvatorische Klausel</strong></p>
            <p>Sollte eine Klausel dieser allgemeinen Geschäftsbedingungen rechtswidrig oder unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt.</p>
            <p>Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die gesetzlich zulässig ist und dem Inhalt der unwirksamen Klausel unter Berücksichtigung der beiderseitigen Interessen möglichst nahe kommt.</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Agb"
    }
</script>

<style scoped>
    #agbWraper{padding:25px 0;}

</style>